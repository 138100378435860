define("discourse/plugins/discourse-landing-pages/discourse/components/json-editor", ["exports", "admin/components/ace-editor"], function (_exports, _aceEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class JsonEditor extends _aceEditor.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "mode", "json");
      _defineProperty(this, "htmlPlaceholder", true);
    }
    _overridePlaceholder(loadedAce) {
      const pluginAcePath = "/plugins/discourse-landing-pages/javascripts/ace";
      loadedAce.config.set("modePath", pluginAcePath);
      loadedAce.config.set("workerPath", pluginAcePath);
      loadedAce.config.set("wrap", true);
      super._overridePlaceholder(...arguments);
    }
  }
  _exports.default = JsonEditor;
});