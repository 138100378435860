define("discourse/plugins/discourse-landing-pages/discourse/components/page-admin", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/string", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page", "discourse/lib/ajax-error", "I18n"], function (_exports, _component, _decorators, _computed, _string, _landingPage, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const location = window.location;
  const port = location.port ? ":" + location.port : "";
  const baseUrl = location.protocol + "//" + location.hostname + port;
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("page.parent_id"), _dec2 = (0, _decorators.default)("page.path", "parent"), _dec3 = (0, _decorators.default)("pagePath"), (_obj = {
    classNames: "page-admin",
    updatingPage: (0, _computed.or)("destroyingPage", "savingPage"),
    hasParent: (0, _computed.notEmpty)("parent"),
    noParent: (0, _computed.not)("hasParent"),
    hasPath: (0, _computed.or)("page.path", "page.parent_id"),
    parent(parentId) {
      const parent = this.pages.findBy("id", parentId);
      return parent ? parent : null;
    },
    pagePath(path, parent) {
      return parent ? parent.path : path;
    },
    pageUrl(pagePath) {
      let url = baseUrl;
      if (pagePath) {
        url += `/${(0, _string.dasherize)(pagePath)}`;
      } else {
        url += `/${_I18n.default.t("admin.landing_pages.page.path.placeholder")}`;
      }
      if (this.hasParent) {
        url += `/1`;
      }
      return url;
    },
    actions: {
      onChangePath(path) {
        if (!this.page.parent_id) {
          this.set("page.path", path);
        }
      },
      onChangeParent(pageId) {
        this.set("page.parent_id", pageId);
      },
      savePage() {
        this.set("savingPage", true);
        const page = this.get("page");
        let self = this;
        page.savePage().then(result => {
          if (result.page) {
            self.setProperties({
              page: _landingPage.default.create(result.page),
              currentPage: JSON.parse(JSON.stringify(result.page))
            });
            self.updatePages(result.pages);
          } else {
            self.set("page", self.currentPage);
          }
        }).catch(error => {
          self.set("resultMessage", {
            type: "error",
            icon: "times",
            text: (0, _ajaxError.extractError)(error)
          });
          setTimeout(() => {
            self.set("resultMessage", null);
          }, 5000);
          if (self.currentPage) {
            self.set("page", self.currentPage);
          }
        }).finally(() => {
          self.set("savingPage", false);
        });
      },
      destroyPage() {
        this.set("destroyingPage", true);
        this.page.destroyPage().then(result => {
          if (result.success) {
            this.setProperties({
              page: null,
              pages: result.pages
            });
          }
        }).finally(() => {
          this.set("destroyingPage", false);
        });
      },
      exportPage() {
        const page = this.get("page");
        let self = this;
        page.exportPage().then(file => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.setAttribute("download", `discourse-${page.name.toLowerCase()}.zip`);
          link.click();
        }).catch(error => {
          self.set("resultMessage", {
            type: "error",
            icon: "times",
            text: (0, _ajaxError.extractError)(error)
          });
          setTimeout(() => {
            self.set("resultMessage", null);
          }, 5000);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "parent", [_dec], Object.getOwnPropertyDescriptor(_obj, "parent"), _obj), _applyDecoratedDescriptor(_obj, "pagePath", [_dec2], Object.getOwnPropertyDescriptor(_obj, "pagePath"), _obj), _applyDecoratedDescriptor(_obj, "pageUrl", [_dec3], Object.getOwnPropertyDescriptor(_obj, "pageUrl"), _obj)), _obj)));
});